import { Tab, Tooltip, Typography } from '@material-ui/core'
import { setProjectYears, setUncSort, updateDeflators } from '@root-gipro/modules/userProjects/store/actions'
import style from '@root-gipro/shared/TabsComponents/TabsComponents.module.scss'
import { IState } from '@root-gipro/store/interfaces'
import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TabsGroup } from '../TabsComponents/CustomTabs'

interface IVersion {
	userProjectId: number
	name: string
	is_actual: boolean
	unc_sort: string
}

const types = [
	{
		name: '2018',
		path: '',
	},
	{
		name: '2024',
		path: '',
	},
]

const TabUnc = ({ path }: { path: string }) => {
	const history = useHistory()
	const dispatch = useDispatch()

	const currentUrl = path.split('/')[1]
	const currentState = currentUrl === 'user-archive' ? 'userArchives' : 'userProjects'
	const { originalObj, projectObj } = useSelector((state: IState) => state[currentState])

	const [selectedTab, setSelectedTab] = useState<string | null>(null)
	const [versions, setVersions] = useState<IVersion[] | []>([])

	useEffect(() => {
		let versionsProject: IVersion[] | [] = []
		if (projectObj.versions) {
			versionsProject = [...projectObj.versions]
		}
		setVersions(versionsProject)
	}, [originalObj])
	const currentYear = new Date().getFullYear()

	const handleChange = useCallback(
		(_: React.ChangeEvent<{}>, newVal: string) => {
			if (currentUrl === 'user-archive') return
			dispatch(setUncSort(newVal))

			const uncSort = [...versions].find((version: IVersion) => version?.unc_sort === newVal)

			if (uncSort) {
				history.push(`/${currentUrl}/project/${uncSort.userProjectId}/parameters`)

				Number(projectObj.unc_sort) === 2024 ? Number(projectObj.unc_sort) - 1 : Number(projectObj.unc_sort)

				if (originalObj.yearBegin && originalObj.yearEndOffer) {
					dispatch(setProjectYears(originalObj.yearBegin, originalObj.yearEndOffer))
				} else {
					dispatch(
						setProjectYears(
							Number(projectObj.unc_sort) === 2024 ? Number(projectObj.unc_sort) - 1 : Number(projectObj.unc_sort),
							currentYear
						)
					)
				}

				dispatch(updateDeflators(originalObj.yearBegin as number, originalObj.yearEndOffer as number))
			} else {
				if (+newVal === 2018) {
					dispatch(setProjectYears(2018, 2025))
					dispatch(updateDeflators(2018, 2025, true))
				} else {
					dispatch(setProjectYears(2023, 2025))
					dispatch(updateDeflators(2023, 2025, true))
				}
			}
		},
		[projectObj.id, originalObj, versions]
	)

	useEffect(() => {
		if (!projectObj.unc_sort) {
			dispatch(setUncSort('2024'))
			dispatch(setProjectYears(2023, 2025))
		}
	}, [])

	return (
		<>
			<TabsGroup
				value={projectObj.unc_sort}
				disabled={currentUrl === 'user-archive'}
				onChange={handleChange}
				className={style.checkPlateTabs}
			>
				{types &&
					types.map((type: any) => (
						<Tooltip
							key={type.name}
							title={
								type.name == 2018 ? (
									<Typography>
										Режим расчета по сборнику УНЦ, утверждённому в 2019 году. Базовыми являются расценки 2018 года
									</Typography>
								) : (
									<Typography>
										Режим расчета по сборнику УНЦ, утверждённому в 2024 году. Базовыми являются расценки 2023 года
									</Typography>
								)
							}
						>
							{projectObj.unc_sort == type.name ? (
								<span>
									<Tab
										className={cn({
											[style.active]: projectObj.unc_sort === type.name,
										})}
										disabled={selectedTab == type.name}
										disableRipple
										label={type.name == 2018 ? '2019' : type.name}
										value={type.name}
									/>
								</span>
							) : (
								<Tab
									className={cn({
										[style.active]: projectObj.unc_sort === type.name,
									})}
									disabled={projectObj.unc_sort == type.name}
									disableRipple
									label={type.name == 2018 ? '2019' : type.name}
									value={type.name}
								/>
							)}
						</Tooltip>
					))}
			</TabsGroup>
		</>
	)
}

export default TabUnc
