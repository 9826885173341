import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { CarouselElem } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/CarouselElem'
import { ICarouselProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'
import { IState } from '@root-gipro/store/interfaces'
import uniqueId from 'lodash/uniqueId'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SwiperCore, { Keyboard, Mousewheel, Navigation } from 'swiper'
import 'swiper/components/navigation/navigation.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
SwiperCore.use([Navigation, Mousewheel, Keyboard])

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popover: {
			pointerEvents: 'none',
		},
		paper: {
			padding: theme.spacing(1),
			backgroundColor: 'orange',
			color: 'white',
		},
	})
)

export const CarouselWide: React.FC<ICarouselProps> = ({
	fullWidth,
	totalInvestmentUncOffer,
	title,
	numberTransformation = false,
	dataArr,
	code,
	disabled,
}) => {
	const button = uniqueId('button')
	const { userProjects } = useSelector((state: IState) => state)
	const [isValid, setIsValid] = useState(false)
	const classes = useStyles()

	const carouselRef = useRef<HTMLDivElement | null>(null)
	const dispatch = useDispatch()
	const handleinitSwiper = (swiper: SwiperCore) => {
		if (swiper) {
			setInterval(() => {
				swiper.navigation?.update()
			}, 0)
		}
	}

	const isValidPair = () => {
		const additionalFundingOffer = userProjects.projectObj.additionalFundingOffer
		if (code === 'offer') {
			if (additionalFundingOffer) {
				return true
			}
			if (dataArr && dataArr.length) {
				const valid = Boolean(dataArr.some(([_, value]) => Number(value) > 0))

				return valid
			} else {
				return true
			}
		} else {
			return true
		}
	}

	useEffect(() => {
		setIsValid(isValidPair())
	}, [userProjects.projectObj.years])

	return (
		<div ref={carouselRef} className={`${'carousel_container'} ${!isValid && 'unValid'}`}>
			<div className='project_carousel_wrapper'>
				<div
					className={`swiper-button-prev ${button}-prev ${dataArr?.length === 1 ? 'swiper-button-disabled' : ''}`}
				></div>
				<div
					className={`swiper-button-next ${button}-next ${dataArr?.length === 1 ? 'swiper-button-disabled' : ''}`}
				></div>
				<div className='project_carousel_wide'>
					<Swiper
						spaceBetween={0}
						initialSlide={0}
						slidesPerView={1}
						navigation={{
							nextEl: `.${button}-next`,
							prevEl: `.${button}-prev `,
						}}
						grabCursor={true}
						mousewheel={true}
						freeMode={true}
						onInit={handleinitSwiper}
					>
						{dataArr?.map((elem, ind: number) => (
							<SwiperSlide key={uniqueId('SwiperSlide')}>
								<CarouselElem
									disabled={disabled}
									code={code}
									key={uniqueId('CarouselElem')}
									label={elem[0] as string}
									value={elem[1] as number}
									numberTransformation={numberTransformation}
									totalInvestmentUncOffer={totalInvestmentUncOffer}
									yearBegin={userProjects.projectObj.yearBegin}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
				<div className='currency'>{code === 'deflator' ? '%' : 'млн.р'}</div>
			</div>
			{/* {code === 'offer' && (
				<Popover
					className={classes.popover}
					classes={{
						paper: classes.paper,
					}}
					open={!isValid}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					marginThreshold={10}
					elevation={10}
					anchorEl={carouselRef.current}
					disableEnforceFocus
					disableAutoFocus
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
				>
					Заполните поле
				</Popover>
			)} */}
		</div>
	)
}
