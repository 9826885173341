import { ICarouselElemProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'
import { IUserProjectState } from '@root-gipro/modules/userProjects/models/interfaces/user-projects.model'
import { setYears } from '@root-gipro/modules/userProjects/store/actions'
import { intFormatPercent } from '@root-gipro/modules/userProjects/utils/intFormatPercent'
import { IState } from '@root-gipro/store/interfaces/state'
import { formatedDeflatorValue } from '@root-gipro/utils/helpers/common'
import produce, { Draft } from 'immer'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const CarouselElem: React.FC<ICarouselElemProps> = ({
	totalInvestmentUncOffer,
	label,
	value,
	numberTransformation,
	code,
	disabled,
	yearBegin,
}) => {
	const [result, setResult] = useState(value ? (numberTransformation ? value / 10 ** 6 : value) : '')
	const { userProjects } = useSelector((state: IState) => state)
	const precision = userProjects.deflators?.precision ? userProjects.deflators?.precision : 0
	const dispatch = useDispatch()
	const inputRef = useRef<HTMLInputElement>(null)
	const position = useRef<number>(1)
	const handleBlur = () => {
		const clone = produce(userProjects, (draft: Draft<IUserProjectState>) => {
			if (result && String(result).match(/\%/) && totalInvestmentUncOffer) {
				const notPercent = String(result).replace('%', '')
				draft.projectObj.years[label][code] = numberTransformation
					? (+totalInvestmentUncOffer * +notPercent) / 100
					: (+notPercent as number)
			} else {
				draft.projectObj.years[label][code] = numberTransformation ? +result * 10 ** 6 : (+result as number)
			}
		})

		dispatch(setYears(clone.projectObj.years))
	}

	const checkLabel = () => {
		if (Number(userProjects.projectObj.unc_sort) === 2024) {
			if (+label > 2022 && +label >= yearBegin!) {
				return false
			} else {
				return true
			}
		}
	}

	const resullt = useMemo(() => {
		return result
	}, [result])
	useEffect(() => {
		if (inputRef.current !== null) {
			inputRef.current.setSelectionRange(position.current, position.current)
		}
	}, [resullt])
	return (
		<div className='carousel_elem'>
			<div className='label'>{label}</div>
			<div className='value'>
				<input
					ref={inputRef}
					onChange={e => {
						let val: string | null = e.currentTarget.value
						val = intFormatPercent(e.currentTarget.value, code)
						position.current = inputRef.current?.selectionStart as number
						setResult(val)
					}}
					onBlur={handleBlur}
					disabled={disabled || checkLabel()}
					// value={String(Number(formatValue(result, precision + 1)).toFixed(precision))}
					value={formatedDeflatorValue(result, precision) as string}
				/>
			</div>
		</div>
	)
}

export { CarouselElem }
