import { AuthType, setAuth } from '@root-gipro/store/actions'
import { authorizeApi, giproApp, logoutApi, modeURL } from '@root-gipro/store/api'
import { call, put, takeLatest } from 'redux-saga/effects'

function* authorize() {
	try {
		const authorize = yield call(authorizeApi)
		if (authorize && authorize.status === 'success') {
			yield put(setAuth(authorize.access_token, authorize.expires_in))
			yield localStorage.setItem('access_token', authorize.access_token)
			yield localStorage.setItem('firstname', authorize.user.firstname)
			yield localStorage.setItem('lastname', authorize.user.lastname)
			yield localStorage.setItem('userId', authorize.user.id)
			yield localStorage.setItem('roleId', authorize.user.roleId)
			yield localStorage.setItem('companyId', authorize.user.companyId)
			yield localStorage.setItem('filialId', authorize.user.filialId)
			yield (giproApp.defaults.headers['Authorization'] = `Bearer ${authorize.access_token}`)
		} else {
			yield localStorage.removeItem('access_token')
			yield (window.location.href = modeURL + '?requestUrl=' + window.location.href)
		}
	} catch (error) {
		console.log(error)
		yield localStorage.removeItem('access_token')
		yield (window.location.href = modeURL)
	}
}

function* logout() {
	try {
		const res = yield call(logoutApi)

		if (res && res.status === 'success') {
			yield localStorage.removeItem('access_token')
			yield localStorage.removeItem('firstname')
			yield localStorage.removeItem('lastname')
			yield localStorage.removeItem('userId')
			yield localStorage.removeItem('roleId')
			yield localStorage.removeItem('companyId')
			yield localStorage.removeItem('filialId')
			yield (window.location.href = modeURL)
		}
	} catch (error) {
		console.log(error)
		yield localStorage.removeItem('access_token')
		yield localStorage.removeItem('firstname')
		yield localStorage.removeItem('lastname')
		yield localStorage.removeItem('userId')
		yield localStorage.removeItem('roleId')
		yield localStorage.removeItem('companyId')
		yield localStorage.removeItem('filialId')
		yield (window.location.href = modeURL)
	}
}

export default function* watchAuthorize() {
	yield takeLatest(AuthType.AUTHORIZE, authorize)
	yield takeLatest(AuthType.LOGOUT, logout)
}
