import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { setT7EditModeArchive } from '@root-gipro/modules/userArchive/store/actions'
import {
	tbodyGroupItems,
	tbodyUnitItems,
	theadUnNormalizedForm20,
	underThead,
} from '@root-gipro/modules/userProjects/models/consts/unNormalizedform20Costs.consts'
import {
	getNonStandardizedCosts,
	saveT7Data,
	saveUserProject,
	setProjectAdditional,
	setT7EditMode,
	setT7Version,
} from '@root-gipro/modules/userProjects/store/actions'
import '@root-gipro/modules/userProjects/styles/T7.scss'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { IT7RowForm20 } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalContext } from '@root-gipro/context/modal-context'
import { filterWidthWindow } from '@root-gipro/modules/userProjects/utils/helpers'
import Loader from '@root-gipro/shared/Loader/Loader'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import TableInput from '../UncTable/TableInput'
import TableSelect from '../UncTable/TableSelect'
import Instructions from './Instructions'

interface IUnNormalizedCostsProps {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	t7EditMode: boolean
	isArchived: boolean
	isLocked: boolean
	isNewVersion: boolean
}

const UnNormalizedForm20Costs: React.FC<IUnNormalizedCostsProps> = ({
	anchorEl,
	setAnchorEl,
	t7EditMode,
	isArchived,
	isLocked,
	isNewVersion,
}) => {
	const { projectObj, t7Data, loadingT7Data } = useSelector((state: IState) => state.userProjects)
	const { accessory, setAccessory, accessoryRef, setAnchorEl: setAnchorElModal, anchorEl: anchorElModal } = useContext(
		ModalContext
	)
	const filterButtonOptions = (step: string) => {
		switch (step) {
			case 'unNormalizeForm20Instructions':
				return {
					PrimaryBtn: {
						handler: () => {
							setAnchorEl!(null!)
						},
						value: 'Готово',
					},
					SecondaryBtn: {
						handler: () => {
							accessoryRef?.current && setAccessory!(accessoryRef.current)
						},
						value: 'Назад',
					},
				}
		}
	}

	const dispatch = useDispatch()

	const [t7Rows, setT7Rows] = useState(t7Data)

	useEffect(() => {
		setT7Rows(t7Data)
	}, [t7Data])

	const cancelHandler = () => {
		setAnchorEl!(null!)
		isArchived ? dispatch(setT7EditModeArchive(false)) : dispatch(setT7EditMode(false))
	}
	const addRow = () => {
		const lastRowNumber = t7Rows[t7Rows.length - 1].row
		const t7Row: IT7RowForm20 = {
			comment: null,
			count: null,
			estimate: null,
			object_name: null,
			phase: null,
			related: null,
			unit: null,
			unn_costs: null,
			unn_group: null,
			unn_k: null,
			unn_name: null,
			row: lastRowNumber + 1,
		}
		setT7Rows((prev: IT7RowForm20[]) => [...prev, t7Row])
	}

	const deleteRow = (rowNumber: number | null, cleaned?: boolean) => {
		if (cleaned) {
			setT7Rows((prev: IT7RowForm20[]) => {
				if (prev.length > 1) {
					return prev.slice(0, -1)
				} else {
					return [
						{
							...prev[0],
							comment: null,
							count: null,
							estimate: null,
							object_name: null,
							phase: null,
							related: null,
							unit: null,
							unn_costs: null,
							unn_group: null,
							unn_k: null,
							unn_name: null,
							row: prev[0].row, // Оставляем номер строки
						},
					]
				}
			})
		} else {
			setT7Rows((prev: IT7RowForm20[]) => prev.filter(t7row => t7row.row !== rowNumber))
		}
	}

	const calcSum = () => {
		let sum = 0
		t7Rows.forEach(function (item: IT7RowForm20) {
			if (item.unn_costs !== null) {
				sum += item.unn_costs * 10 ** 3
			} else {
				return 0
			}
		})
		return sum
	}

	const readyHandler = (e: any) => {
		if (e.currentTarget.dataset.popup === 'close-table-form20') {
			setAnchorEl!(null!)
		}
		dispatch(setProjectAdditional(calcSum()))

		const clone = Object.assign({}, projectObj)
		clone.additionalFundingOffer = calcSum()
		delete clone.id
		delete clone.author
		dispatch(saveUserProject(clone, false, projectObj.id))

		dispatch(saveT7Data(t7Rows, projectObj.id!, 2))
		dispatch(setT7EditMode(false))
		dispatch(setT7Version(2))
	}

	const changeHandle = (event: any, t7row: IT7RowForm20) => {
		const fieldName: string = event.target.name
		const fieldValue = event.target.value

		if (fieldName === 'unn_costs') {
			calcSum()
		}
		const t7Row: IT7RowForm20 = {
			comment: fieldName === 'comment' ? fieldValue : t7row.comment,
			count: fieldName === 'count' ? fieldValue : t7row.count,
			estimate: fieldName === 'estimate' ? fieldValue : t7row.estimate,
			object_name: fieldName === 'object_name' ? fieldValue : t7row.object_name,
			phase: fieldName === 'phase' ? fieldValue : t7row.phase,
			related: fieldName === 'related' ? fieldValue : t7row.related,
			unit: fieldName === 'unit' ? fieldValue : t7row.unit,
			unn_costs: fieldName === 'unn_costs' ? fieldValue : t7row.unn_costs,
			unn_group: fieldName === 'unn_group' ? fieldValue : t7row.unn_group,
			unn_k: fieldName === 'unn_k' ? fieldValue : t7row.unn_k,
			unn_name: fieldName === 'unn_name' ? fieldValue : t7row.unn_name,
			row: t7row.row,
		}

		const clone = t7Rows.slice()
		const newRows = clone.map((item: any) => {
			return item.row === t7row.row ? t7Row : item
		})
		setT7Rows(newRows)
	}

	const calcUnnK = (unn_costs: any, count: any) => {
		if (unn_costs === null || unn_costs === 0) {
			return 0
		} else {
			unn_costs = +unn_costs
		}
		if (count === null || count === 0) {
			return 0
		} else {
			count = +count
		}

		return (unn_costs / count).toFixed(3)
	}
	useEffect(() => {
		dispatch(getNonStandardizedCosts())
	}, [])
	const unNormalizeInstructions = useSelector((state: IState) => state.userProjects.nonStandardizedCosts)
	const unNormalizeInstructionsList = unNormalizeInstructions.map(item => {
		return { value: item.group, title: item.name }
	})
	return (
		<Modal
			keyBtn={accessory}
			buttonOptions={accessory ? filterButtonOptions(accessory) : null}
			setAnchorEl={setAnchorEl}
			anchorEl={anchorEl}
			widthWindow={filterWidthWindow(accessory)}
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
		>
			{accessory === 'open-table-form20' && (
				<div className='unc_add'>
					<div className='scrollarea1' style={{ overflow: 'hidden', margin: 'auto' }}>
						<div className='unormcsts_panel' style={{ overflow: 'hidden', padding: 0, marginLeft: 0 }}>
							<div style={{ display: 'flex ', justifyContent: 'end', alignItems: 'center' }}>
								<IconButton onClick={cancelHandler}>
									<CloseIcon />
								</IconButton>
							</div>
							<div className='t7title'>Заполнение НЗ по форме вкладки 20.2 формы 20</div>
							<div style={{ overflow: 'auto', maxHeight: 500 }}>
								{loadingT7Data ? (
									<Loader />
								) : (
									<table className='divTable unnormalizedCostsForm20' width='100%'>
										<thead className='thead'>
											<tr className='divTableRow'>
												<th></th>
												{theadUnNormalizedForm20.map(thead => (
													<th className='divTableHead' key={thead.name}>
														<span className='theadName'>{thead.name}</span>
													</th>
												))}
											</tr>
										</thead>
										<tbody>
											<tr className='divTableRow' style={{ paddingBottom: '20px' }}>
												<td></td>
												{underThead.map(el => (
													<td className='divTableCell' key={el}>
														{el}
													</td>
												))}
											</tr>

											{t7Rows.map((t7row: any, index: number) => (
												<tr className='divTableRow dataRow' key={index}>
													<td className='divTableCellCenter'>
														<IconButton
															size='small'
															onClick={() => {
																deleteRow(t7row.row, index === 0 ? true : false)
															}}
														>
															<span className='delete-icon' title='Удалить'></span>
														</IconButton>
													</td>
													<td className='divTableCellCenter'>
														<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
															{index + 1}
														</div>
													</td>
													<td className='divTableCellCenter'>
														<TableSelect
															t7row={t7row}
															handler={changeHandle}
															disabled={isArchived || isLocked}
															items={(isNewVersion ? unNormalizeInstructionsList : tbodyGroupItems)
																.filter((el: any) => {
																	return el.value !== t7row.unn_group
																})
																.sort((a: any, b: any) => {
																	// Извлекаем числовую часть перед "_НЗ" и сравниваем как числа
																	const numA = parseInt(a.value.split('_')[0], 10)
																	const numB = parseInt(b.value.split('_')[0], 10)
																	return numA - numB
																})}
															name={'unn_group'}
															value={t7row.unn_group}
														/>
													</td>
													<td className='divTableCell'>
														<TableInput
															value={t7row.unn_name}
															t7row={t7row}
															handler={changeHandle}
															disabled={isArchived || isLocked}
															name={'unn_name'}
														/>
													</td>
													<td className='divTableCell'>
														<TableInput
															value={t7row.object_name}
															t7row={t7row}
															handler={changeHandle}
															disabled={isArchived || isLocked}
															name={'object_name'}
														/>
													</td>
													<td className='divTableCellCenter'>
														<TableInput
															value={t7row.phase}
															t7row={t7row}
															handler={changeHandle}
															disabled={isArchived || isLocked}
															name={'phase'}
														/>
													</td>
													<td className='divTableCellCenter'>
														<TableInput
															value={t7row.related}
															t7row={t7row}
															handler={changeHandle}
															disabled={isArchived || isLocked}
															name={'related'}
														/>
													</td>
													<td className='divTableCell'>
														<TableInput
															value={t7row.count}
															t7row={t7row}
															handler={changeHandle}
															inputType='number'
															disabled={isArchived || isLocked}
															name={'count'}
														/>
													</td>
													<td className='divTableCell'>
														<TableSelect
															t7row={t7row}
															handler={changeHandle}
															items={tbodyUnitItems
																.filter(el => {
																	return el.value !== t7row.unit
																})
																.sort()}
															name={'unit'}
															disabled={isArchived || isLocked}
															value={t7row.unit}
														/>
													</td>
													<td className='divTableCell'>
														<TableInput
															value={t7row.estimate}
															t7row={t7row}
															handler={changeHandle}
															disabled={isArchived || isLocked}
															name={'estimate'}
														/>
													</td>
													<td className='divTableCell'>
														<TableInput
															value={t7row.unn_costs}
															t7row={t7row}
															inputType='number'
															handler={changeHandle}
															disabled={isArchived || isLocked}
															name={'unn_costs'}
														/>
													</td>
													<td className='divTableCell'>
														<TableInput
															value={() => calcUnnK(t7row.unn_costs, t7row.count)}
															t7row={t7row}
															inputType='number'
															handler={changeHandle}
															disabled={true}
															name={'unn_k'}
														/>
													</td>
													<td className='divTableCell'>
														<TableInput
															value={t7row.comment}
															t7row={t7row}
															handler={changeHandle}
															disabled={isArchived || isLocked}
															name={'comment'}
														/>
													</td>
												</tr>
											))}

											<tr className='divTableRow noBorder'>
												<td className='divTableCellCenter '></td>
												<td className='divTableCell title-total' style={{ textAlign: 'left' }} colSpan={8}>
													Итого объем финансовых потребностей, тыс. рублей (с налогами и сборами)
												</td>
												<td className='divTableCellCenter title-total' colSpan={3}>
													<span style={{ marginLeft: '-50px' }}>{prettyCost(calcSum() / 10 ** 3)} тыс.р</span>
												</td>
												<td className='divTableCellCenter'></td>
												<td className='divTableCellCenter'></td>
											</tr>
										</tbody>
									</table>
								)}
							</div>

							{!isArchived || !isLocked ? (
								<div className='unnormalized__footer'>
									<div className='action_btns mt-8'>
										{t7Rows.length > 0 && (
											<PrimaryBtn variant='contained' data-popup='close-table-form20' onClick={readyHandler}>
												Сохранить
											</PrimaryBtn>
										)}
										<SecondaryBtn variant='contained' onClick={cancelHandler} className='rej_btn'>
											Отмена
										</SecondaryBtn>
										<SecondaryBtn variant='contained' onClick={addRow} className='rej_btn'>
											Добавить строку
										</SecondaryBtn>
										<button
											className='note_btn'
											data-popup='unNormalizeForm20Instructions'
											onClick={e => {
												setAccessory!('unNormalizeForm20Instructions')
												readyHandler(e)
												if (accessoryRef) accessoryRef.current = accessory
											}}
										>
											<p>Указания</p>
										</button>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			)}
			{accessory === 'unNormalizeForm20Instructions' && (
				<Instructions
					setAnchorEl={setAnchorElModal!}
					anchorEl={anchorElModal}
					setAccessory={setAccessory}
					accessory={accessory}
					accessoryRef={accessoryRef}
					handler={() => {
						setAccessory!('open-table-form20')
						if (accessoryRef) accessoryRef.current = accessory
					}}
					isNewVersion={isNewVersion}
				/>
			)}
		</Modal>
	)
}

export default UnNormalizedForm20Costs
