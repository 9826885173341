import React, { useCallback, useContext } from 'react'
import { UncAddR1Window } from '@root-gipro/modules/userProjects/components/Project/Modal'
import { ISelectTableHeader } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'

import { ModalContext } from '@root-gipro/context/modal-context'
import { ToggleTabs } from '@root-gipro/modules/userProjects/components/Project/UncAdd/ToggleTabs'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'

import { tbodyArr } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'
import { useDispatch } from 'react-redux'
import { changeUncCheckedTable } from '@root-gipro/modules/userProjects/store/actions'

export const SelectTableHeader: React.FC<ISelectTableHeader> = ({
	uncTableData,
	setChecked,
	selectedElem,
	tableNums,
	tableTitles,
	checkedTable,
	setShowInter,
	showInter,
	createSpecialUnc,
}) => {
	const dispatch = useDispatch()
	const { setAnchorEl, anchorEl } = useContext(ModalContext)

	const items = tbodyArr
		.filter(item => {
			if (selectedElem?.code === 'С1') {
				return item.addUncShowing
			} else {
				return item.tableNum !== 8 && item.tableNum !== 6
			}
		})
		.map(item => {
			return {
				name: item.title,
				value: item.tableNum + '',
			}
		})
	const openWindow = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			setAnchorEl!(e.currentTarget)
		},
		[setAnchorEl]
	)

	return (
		<div className='select-table__header wrapper__toggler'>
			<ToggleTabs
				defaultValue={selectedElem?.code === 'С1' ? items.length - 1 : checkedTable >= 6 ? 0 : checkedTable - 1}
				items={items}
				clickHandler={item => {
					setChecked(item.value)
					dispatch(changeUncCheckedTable(Number(item.value)))
					
				}}
				selectedElemCode={selectedElem?.code}
			/>
			{uncTableData && uncTableData.interpolation > 0 && selectedElem?.code !== 'Б1' && (
				<div className='interpol_wrapper ml-8'>
					<SecondaryBtn data-popup='unc-add-r1' onClick={openWindow}>
						Задать
					</SecondaryBtn>
					{anchorEl?.dataset.popup === 'unc-add-r1' && (
						<UncAddR1Window
							handler={createSpecialUnc}
							data={uncTableData.selects}
							tableId={uncTableData.id}
							anchorEl={anchorEl!}
							setAnchorEl={setAnchorEl!}
						/>
					)}
				</div>
			)}
		</div>
	)
}
